import { useState, useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import FormContainer from "../../components/FormContainer";
import { toast } from "react-toastify";
import {
  useUpdateImageMutation,
  useGetImageDetailsQuery,
  usePostImageMutation,
} from "../../slices/imagesApiSlice";


const ImageEditScreen = () => {
  const { id: imageId } = useParams();

  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [uploadedBy, setUploadedBy] = useState("");
  const [views, setViews] = useState(0);
  const [description, setDescription] = useState("");
  const [placeOfDeath, setPlaceOfDeath] = useState(""); // added this
  const [dateOfDeath, setDateOfDeath] = useState(""); //added this
  const [placeOfBirth, setPlaceOfBirth] = useState(""); // added this
  const [dateOfBirth, setDateOfBirth] = useState(""); // added this
  const [category, setCategory] = useState(""); // added this

  const {
    data: image,
    isLoading,
    refetch,
    error,
  } = useGetImageDetailsQuery(imageId);

  const [updateImage] = useUpdateImageMutation();

  const [postImage] = usePostImageMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (image) {
      setName(image.name || "");
      setPhoto(image.photo || "");
      setUploadedBy(image.uploadedBy || "");
      setViews(image.views || 0);
      setDescription(image.description || "");
      setPlaceOfDeath(image.placeOfDeath || ""); // added this
      setDateOfDeath(image.dateOfDeath || ""); // added this
      setPlaceOfBirth(image.placeOfBirth || ""); // added this
      setDateOfBirth(image.dateOfBirth || ""); // added this
      setCategory(image.category || ""); // added this
    }
  }, [image]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const updatedImage = {
        imageId,
        name,
        photo,
        uploadedBy,
        views,
        description,
        placeOfDeath, 
        dateOfDeath, 
        placeOfBirth, 
        dateOfBirth, 
        category, 
      };

      const result = await updateImage(updatedImage);
      console.log(updatedImage);
      if (result.error) {
        toast.error(result.error);
      } else {
        toast.success("Image updated");
        refetch();
        navigate("/admin/imagesList");
      }
    } catch (error) {
      console.log("exception caught", error);
      toast.error("error updating image");
    }
  };

  const postFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const result = await postImage(formData).unwrap();
      toast.success(result.message);
      setPhoto(result.image.src);
    } catch (error) {
      // toast.error(error?.data?.message || error.error);
      toast.error(JSON.stringify(error?.data?.message || error.error));
    }
  };

  return (
    <>
      <Link to="/admin/imagesList" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Image</h1>

        {isLoading && <p>Loading...</p>}
        {error && <p>{error}</p>}

        {/* {error && <p>{error.message || JSON.stringify(error)}</p>} */}

        <Form onSubmit={submitHandler}>

        <Form.Group controlId="category" className="my-2">
    <Form.Label>Category</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter category"
      value={category}
      onChange={(e) => setCategory(e.target.value)}
    ></Form.Control>
</Form.Group>




          <Form.Group controlId="name" className="my-2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          {/* image input placeholder */}
          <Form.Group controlId="image" className="my-2">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="text"
              placeholder="enter image url"
              value={photo}
              onChange={(e) => setPhoto(e.target.value)}
            ></Form.Control>
            <Form.Control
              type="file"
              label="Choose File"
              onChange={postFileHandler}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="description" className="my-2">
            <Form.Label>Enter Description</Form.Label>
            <Form.Control
              // type="text"
              as="textarea"
              placeholder="Enter Description"
              value={description}
              required
              onChange={(e) => setDescription(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="placeOfBirth" className="my-2">
            <Form.Label>Place of Birth</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter place of birth"
              value={placeOfBirth}
              onChange={(e) => setPlaceOfBirth(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="dateOfBirth" className="my-2">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter date of birth"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="placeOfDeath" className="my-2">
            <Form.Label>Place of Death</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter place of death"
              value={placeOfDeath}
              onChange={(e) => setPlaceOfDeath(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="dateOfDeath" className="my-2">
            <Form.Label>Date Of Death</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter date of death"
              value={dateOfDeath}
              onChange={(e) => setDateOfDeath(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="uploadedBy" className="my-2">
            <Form.Label>Uploaded By</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name of uploader"
              value={uploadedBy}
              onChange={(e) => setUploadedBy(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="my-4">
            Update
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default ImageEditScreen;
