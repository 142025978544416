import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useCreateImageMutation,
  usePostImageMutation,
} from "../slices/imagesApiSlice";
import { Card, Form, Button } from "react-bootstrap";
import { categoryItems } from "../utils/categoryItems";
import InstitutionsForm from "../components/InstitutionsForm";
import MartyrsForm from "../components/MartyrsForm";
import CiviliansForm from "../components/CiviliansForm";
import PlaceOfWorshipForm from "../components/PlaceOfWorshipForm";
import GenocideVictims from "../components/GenocideVictims";
import AxumForm from "../components/AxumForm";

const Contact = () => {
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [description, setDescription] = useState("");
  // const [uploadedBy, setUploadedBy] = useState("");

  // const [views, setViews] = useState("");
  const [dateOfDeath, setDateOfDeath] = useState("");
  const [placeOfDeath, setPlaceOfDeath] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [category, setCategory] = useState("");

  // check for error
  const [descriptionError, setDescriptionError] = useState("");
  const [nameError, setNameError] = useState("");
  const [photoError, setPhotoError] = useState("");

  const { userInfo } = useSelector((state) => state.auth);
  const [uploadedBy] = useState(userInfo ? userInfo.name : "");

  // custom toast


  // const navigate = useNavigate();

  const [createImage] = useCreateImageMutation();
  const [postImage] = usePostImageMutation();

  const createImageHandler = async () => {
    // Reset error states
    setDescriptionError("");
    setNameError("");
    setPhotoError("");

    // validation
    if (!name.trim()) {
      setNameError("Name is required!");
    }
    if (!photo.trim()) {
      setPhotoError("Photo is required!");
    }

    if (!description.trim()) {
      setDescriptionError("Description is required!");
    }

    if (nameError || photoError || descriptionError) {
      return; // Stop processing if there's an error
    }

    const formData = {
      name,
      photo,
      uploadedBy,
      dateOfDeath,
      placeOfDeath,
      placeOfBirth,
      dateOfBirth,
      description,
      category,
    };

    try {
      const result = await createImage(formData);
      if (result && result.data && result.data._id) {
        toast.success("Image added successfully");
           // Clear the fields
           setName('');
           setPhoto(''); 
          //  setUploadedBy('');
           setDateOfDeath('');
           setPlaceOfDeath('');
           setPlaceOfBirth('');
           setDateOfBirth('');
           setDescription('');
           setCategory('');
      } else {
        toast.error("Failed to create image");
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const postFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const result = await postImage(formData).unwrap();
      toast.success(result.message);
      setPhoto(result.image.src);
    } catch (error) {
      toast.error(JSON.stringify(error?.data?.message || error.error));
    }
  };

  return (
    <Card className="contact-card shadow-sm">
      <Link to="/" className="goback-btn btn-light mb-4">
        Go Back
      </Link>

      <Card.Body>
        {userInfo ? (
          <Form className="contact-form">
            <Form.Group controlId="category" className="my-2">
              <Form.Label>Choose Category</Form.Label>
              <Form.Control
                as="select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Choose Category</option>
                {categoryItems.map((item, index) => {
                  if (index === 0) return null; // Skip the first item

                  return (
                    <option key={item.id} value={item.category}>
                      {item.category}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            {/* Institutions form structure */}
            {category === "institutions" && (
              <InstitutionsForm
                name={name}
                setName={setName}
                nameError={nameError}
                photo={photo}
                setPhoto={setPhoto}
                postFileHandler={postFileHandler}
                description={description}
                setDescription={setDescription}
                descriptionError={descriptionError}
              />
            )}

            {/* Place of worship form structure */}
            {category === "place of worship" && (
              <PlaceOfWorshipForm
                name={name}
                setName={setName}
                nameError={nameError}
                photo={photo}
                setPhoto={setPhoto}
                postFileHandler={postFileHandler}
                description={description}
                setDescription={setDescription}
                descriptionError={descriptionError}
              />
            )}

            {/* Genocide Victims form structure */}
            {category === "genocide victims" && (
              <GenocideVictims
                name={name}
                setName={setName}
                nameError={nameError}
                photo={photo}
                setPhoto={setPhoto}
                postFileHandler={postFileHandler}
                description={description}
                setDescription={setDescription}
                descriptionError={descriptionError}
              />
            )}

            {/* Martyrs form structure */}
            {category === "martyrs" && (
              <MartyrsForm
                name={name}
                setName={setName}
                nameError={nameError}
                photo={photo}
                setPhoto={setPhoto}
                postFileHandler={postFileHandler}
                description={description}
                setDescription={setDescription}
                descriptionError={descriptionError}
                placeOfBirth={placeOfBirth}
                setPlaceOfBirth={setPlaceOfBirth}
                dateOfBirth={dateOfBirth}
                setDateOfBirth={setDateOfBirth}
                placeOfDeath={placeOfDeath}
                setPlaceOfDeath={setPlaceOfDeath}
                dateOfDeath={dateOfDeath}
                setDateOfDeath={setDateOfDeath}
                uploadedBy={uploadedBy}
              />
            )}
            {/* Civilians form structure */}
            {category === "civilians" && (
              <CiviliansForm
                name={name}
                setName={setName}
                nameError={nameError}
                photo={photo}
                setPhoto={setPhoto}
                postFileHandler={postFileHandler}
                description={description}
                setDescription={setDescription}
                descriptionError={descriptionError}
                placeOfBirth={placeOfBirth}
                setPlaceOfBirth={setPlaceOfBirth}
                dateOfBirth={dateOfBirth}
                setDateOfBirth={setDateOfBirth}
                placeOfDeath={placeOfDeath}
                setPlaceOfDeath={setPlaceOfDeath}
                dateOfDeath={dateOfDeath}
                setDateOfDeath={setDateOfDeath}
                uploadedBy={uploadedBy}
              />
            )}

            {/* Axum Massacre form structure */}
            {category === "axum massacre" && (
              <AxumForm
                name={name}
                setName={setName}
                nameError={nameError}
                photo={photo}
                setPhoto={setPhoto}
                postFileHandler={postFileHandler}
                description={description}
                setDescription={setDescription}
                descriptionError={descriptionError}
                placeOfBirth={placeOfBirth}
                setPlaceOfBirth={setPlaceOfBirth}
                dateOfBirth={dateOfBirth}
                setDateOfBirth={setDateOfBirth}
                placeOfDeath={placeOfDeath}
                setPlaceOfDeath={setPlaceOfDeath}
                dateOfDeath={dateOfDeath}
                setDateOfDeath={setDateOfDeath}
                uploadedBy={uploadedBy}
              />
            )}

            <Button
              type="button"
              onClick={createImageHandler}
              variant="primary"
              className="my-4"
            >
              Create Image
            </Button>
          </Form>
        ) : (
          <Card className="custom-card">
            <Card.Body>
              <p>
                You need to <Link to="/login">login/register</Link> to create an
                image.
              </p>
            </Card.Body>
          </Card>
        )}
      </Card.Body>
    </Card>
  );
};

export default Contact;
