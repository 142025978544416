import React from 'react';
import { categoryItems } from '../utils/categoryItems';

const Category = ({ onCategorySelect, currentCategory }) => {
    return (
      <div className="btn-container">
        {categoryItems.map(item => (
          <button
            type="button"
            className={`category-btn ${item.category === currentCategory ? 'active' : ''}`}
            key={item.id}
            onClick={() => onCategorySelect(item.category)}
          >
            {item.category}
          </button>
        ))}
      </div>
    );
  }
  

export default Category;



