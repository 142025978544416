import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa";
import "../App.css";

const Footer = () => {
  return (
    
 
  
    <Container fluid className="footer-container text-center py-3">
      <Row className="align-items-center justify-content-md-center">
        <Col md="auto" className="text-center py-3 ">
          <p className="footer-h5 ">
            Made with <span className="heart">❤</span>
          </p>
        </Col>

        <Col md="auto" className="text-center py-3 mr-3">
          <h5 className="footer-h5">
            &copy; {new Date().getFullYear()}
            <span> Tigray</span>Gallery
          </h5>
        </Col>

        <Col md="auto" className="text-center py-3 mr-3">
          <a
            href="https://twitter.com/TigrayGallery"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={30} className="mr-2 footer-icon" />
          </a>
          <a
            href="https://www.facebook.com/dejena1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={30} className="footer-icon" />
          </a>

          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={30} className="footer-icon" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
