import { useState } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'

const SearchBox = () => {
const navigate = useNavigate ();
const { keyword: urlkeyword } = useParams ();
const [keyword, setKeyword] = useState (urlkeyword || '');
const [errorMsg, setErrorMsg] = useState('');  // State for handling the error message


const submitHandler = (e) => {
    e.preventDefault ();
    if (keyword.trim ()) {
        setKeyword ('');
        navigate (`/search/${keyword}`);
        setErrorMsg('');  // Clear the error message if any

    } else {
        setErrorMsg("Please enter a valid name to search.");  // Set the error message

        navigate ('/');
    }
}




  return (
    <div>
              {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}  {/* Display the error message if it exists */}

<Form onSubmit={submitHandler} className='search-box d-flex'>
    <Form.Control
        type='text'
        name='q'
        onChange={(e) => setKeyword(e.target.value)}
        placeholder='Search Your Heros here / ሽም ጅግና ይጽሓፉ ...'
        className='mr-sm-2 ml-sm-5'
        value={keyword}
    ></Form.Control>
    <Button type='submit' variant='outline-light' className=' search-button p-2 mx-2 '>
        Search
    </Button>
</Form>

</div>
  )
}

export default SearchBox

