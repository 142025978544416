import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
const Paginate = ({
  pages: totalPages,
  page: currentPage,
  isAdmin = false,
  keyword = "",
}) => {
  const createPaginationItems = () => {
    let items = [];
    let pageCutLow = currentPage - 1;
    let pageCutHigh = currentPage + 1;

    // Previous page
    if (currentPage > 1) {
      items.push(
        <LinkContainer key="prev" to={getPageLink(currentPage - 1)}>
          <Pagination.Item className="prev-next">
            <span className="prev-next-text">Prev</span>
          </Pagination.Item>
        </LinkContainer>
      );
    }

    if (totalPages < 6) {
      for (let p = 1; p <= totalPages; p++) {
        items.push(renderPaginationItem(p));
      }
    } else {
      if (currentPage > 2) {
        items.push(renderPaginationItem(1));
        if (currentPage > 3) {
          items.push(
            <Pagination.Ellipsis key="ellipsis-start" className="ellipsis" />
          );
        }
      }

      if (currentPage === 1) {
        pageCutHigh += 2;
      } else if (currentPage === 2) {
        pageCutHigh += 1;
      }

      if (currentPage === totalPages) {
        pageCutLow -= 2;
      } else if (currentPage === totalPages - 1) {
        pageCutLow -= 1;
      }

      for (let p = pageCutLow; p <= pageCutHigh; p++) {
        if (p === 0) {
          p += 1;
        }
        if (p > totalPages) {
          continue;
        }
        items.push(renderPaginationItem(p));
      }

      if (currentPage < totalPages - 1) {
        if (currentPage < totalPages - 2) {
          items.push(
            <Pagination.Ellipsis key="ellipsis-end" className="ellipsis" />
          );
        }
        items.push(renderPaginationItem(totalPages));
      }
    }

    // Next page
    if (currentPage < totalPages) {
      items.push(
        <LinkContainer key="next" to={getPageLink(currentPage + 1)}>
          <Pagination.Item className="prev-next">
            <span className="prev-next-text">Next</span>
          </Pagination.Item>
        </LinkContainer>
      );
    }

    return items;
  };

  const getPageLink = (pageNum) => {
    if (!isAdmin) {
      return keyword
        ? `/search/${keyword}/page/${pageNum}`
        : `/page/${pageNum}`;
    }
    return `/admin/imageslist/${pageNum}`;
  };

  const renderPaginationItem = (pageNum) => {
    return (
      <LinkContainer key={pageNum} to={getPageLink(pageNum)}>
        <Pagination.Item active={pageNum === currentPage} className="itemStyle">
          {pageNum}
        </Pagination.Item>
      </LinkContainer>
    );
  };

  return (
    totalPages > 1 && (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination className="itemStyle">{createPaginationItems()}</Pagination>
      </div>
    )
  );
};

export default Paginate;
