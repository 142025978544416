
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { about } from "../utils/util";
import "../assets/styles/about.css";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <Container>
          <Row className="mb-4">
            <Col>
              <h3 className="header-title">About Us</h3>
              <div className="underline"></div>
            </Col>
          </Row>

          <Row className="mb-4">
            {about.map((service) => {
              const { id, title, text } = service;
              return (
                <Col md={4} key={id} className="mb-4">
                  <div className="service">
                    <div className="service-title">
                      <h4>{title}</h4>
                    </div>
                    <p
                      className="service-text"
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;
