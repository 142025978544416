
import React from 'react'
import { GiCompass, GiVirtualMarker,GiTeacher } from 'react-icons/gi'

export const services = [
    {
      id: 1,
      icon: <GiCompass />,
        title: 'Digital Archiving and Preservation',
        text: 'Digitally store and archive precious historical documents, photographs, preserving and honoring the memories of veterans and documenting the truths of TigrayGenocide and wartime events. This is beneficial for historical societies, museums, or individuals with significant collections and ensuring that the memories of the brave souls and the lessons of history remain alive for future generations..',
   
    },
    {
      id: 2,
      icon: <GiVirtualMarker />,
      title: 'Virtual Tours and Exhibitions',
      text: 'Explore curated galleries and exhibitions from the comfort of their homes.',
    
    },
    {
      id: 3,
      icon: <GiTeacher />,
      title: 'Educational Workshops and Webinars',
      text: 'Learn about the history of a particular place or event, or learn how to use a new technology.',
    },
  ]