import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice"
import { toast } from "react-toastify";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, { isLoading, error, data }] = useLoginMutation();

   const { userInfo } = useSelector((state) => state.auth); 

   const { search } = useLocation();
   const sp = new URLSearchParams(search);
   const redirect = sp.get('redirect') || '/';

    useEffect(() => {
  
        if (userInfo) {
            navigate(redirect);
        }
    }, [navigate, redirect, userInfo]);




  const submitHandler = async (e) => {
    e.preventDefault();
try {
    const res = await login({ email, password }).unwrap();
    dispatch(setCredentials({...res, }));
    navigate(redirect);
    
} catch (error) {
    toast.error(error?.data?.message || error.error);
}
  };

  return (
    <div style={{ minHeight: 'calc(80vh - 60px)', display: 'flex', flexDirection: 'column' }}>
    <FormContainer >
      <h1>Sign In Or  Create Account</h1>    
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant='primary' className='mt-2' >
            Sign In
        </Button>
      </Form>
<Row className='py-3'>
    <Col>
        New Customer? <Link to={redirect ? `/register?redirect=${redirect}` : '/register' }> Create Account</Link>
       
    </Col>
</Row>
    </FormContainer>
    </div>
  );
};
export default LoginScreen;
