import { IMAGES_URL, POST_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const imagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getImages: builder.query({
      query: ({ keyword = '', pageNumber = 1, category = null }) => {
        let params = { keyword, pageNumber };
        if (category) {
          params.category = category;
        }
        return {
          url: IMAGES_URL,
          params: params,
        };
      },
      keepUnusedDataFor: 5,
      providesTags: ["Images"],
    }),
    


    // getImages: builder.query({
    //   query: ({ keyword = '', pageNumber = 1 }) => ({
    //     url: IMAGES_URL,
    //     params: { keyword, pageNumber },
      
    //   }),
    //   keepUnusedDataFor: 5,
    //   providesTags: ["Images"],
    // }),

    getImageDetails: builder.query({
      query: (imageId) => ({
        url: `${IMAGES_URL}${imageId}/`,
      }),
      keepUnusedDataFor: 5,
    }),

    // createImage: builder.mutation({
    //   query: () => ({
    //     url: `${IMAGES_URL}`,
    //     method: "POST",
    //   }),
    //   invalidatesTags: ["Image"],
    // }),

    createImage: builder.mutation({
      query: (imageData) => ({
        url: `${IMAGES_URL}`,
        method: "POST",
        body: imageData,
      }),
      invalidatesTags: ["Image"],
    }),
    

   
  

    updateImage: builder.mutation({
      query: (data) => ({
        url: `${IMAGES_URL}/${data.imageId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Images"],
    }),
    postImage: builder.mutation({
      query: (data) => ({
        url: `${POST_URL}`,
        method: "POST",
        body: data,
      }),
    }),

    deleteImage: builder.mutation({
      query: (imageId) => ({
        url: `${IMAGES_URL}${imageId}`,
        method: "DELETE",
      }),
    }),

    createReview: builder.mutation({
      query: (data) => ({
        url: `${IMAGES_URL}/${data.imageId}/reviews`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Image"],
    }),




    getTopImages: builder.query({
      query: () => ({
        url: `${IMAGES_URL}/top`,
      }),
      keepUnusedDataFor: 5,
    }),

    updateImageViews: builder.mutation({
      query: (imageId) => ({
        url: `${IMAGES_URL}/${imageId}/views`,
        method: "PUT",
      }),
      invalidatesTags: ["Images"],
    }),
  }),
});

// conventional names
export const {
  useGetImagesQuery,
  useGetImageDetailsQuery,
  useCreateImageMutation,
  useUpdateImageMutation,
  usePostImageMutation,
  useDeleteImageMutation,
  useCreateReviewMutation,
  useGetTopImagesQuery,
  useUpdateImageViewsMutation,
} = imagesApiSlice;
