import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { women } from "../utils/women";

const Women = () => {
  return (
    // <div className="py-5 bg-light " >
    <div className="py-5"  style={{ minHeight: 'calc(100vh - 60px)', display: 'flex', flexDirection: 'column' }} >
      <Container>
        <Row className="mb-4 ">
          <Col>
            <h3 className="text-primary text-center" style={{ textTransform: 'capitalize' }}>
             Tigray Women
            </h3>
          </Col>
        </Row>

        <Row className="mb-4 ">
          {women.map((service) => {
            const { id, title, text } = service;
            return (
              <Col md={12} key={id} className="mb-4">
                <div className="service bg-secondary p-4 rounded h-100 d-flex flex-column align-items-center justify-content-center">
                
                  <div className="mt-4 text-center">
                    <h4 className="text-white mb-4" style={{ textTransform: 'capitalize' }}>{title}</h4>
                    <p className="text-white" style={{ fontSize: '1.25rem', textAlign: 'left', margin: '40px'}}>{text}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Women;