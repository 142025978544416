import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import Paginate from "../../components/Paginate";
import {
  useGetImagesQuery,
  useCreateImageMutation,
  useDeleteImageMutation,
} from "../../slices/imagesApiSlice";

const ImagesListScreen = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error, refetch } = useGetImagesQuery({ pageNumber});

  const [createImage, { isLoading: loadingCreate }] = useCreateImageMutation();

  const [deleteImage, { isLoading: loadingDelete }] = useDeleteImageMutation();

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      try {
        await deleteImage(id);
        toast.success("Image deleted successfully");
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const createImageHandler = async () => {
    if (window.confirm("Are you sure you want to upload a new image?")) {
      try {
        await createImage();
        toast.success("Image created successfully");
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>Images</h1>
        </Col>
        <Col className="text-end">
          <Button className="my-3" onClick={createImageHandler}>
            <FaEdit /> Create Image
          </Button>
        </Col>
      </Row>
      {loadingCreate && <h2>Loading...</h2>}
      {loadingDelete && <h2>Loading...</h2>}
      {isLoading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h3>{error}</h3>
      ) : (
        <>
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>ID</th>
              {/* <th>IMAGE</th> */}

              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th>UPLOADED BY</th>


              {/* <th>CREATED AT</th>
              <th>UPDATED AT</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.images.map((image) => (
              <tr key={image._id}>
                <td>{image._id}</td>
                {/* <td>{image.image}</td> */}
                <td>{image.name}</td>
                <td>{image.description}</td>
                <td>{image.uploadedBy}</td>
                {/* <td>{image.createdAt}</td>
                <td>{image.updatedAt}</td> */}
                <td>
                  <LinkContainer to={`/admin/image/${image._id}/edit`}>
                    <Button variant="light" className="btn-sm">
                      <FaEdit />
                    </Button>
                  </LinkContainer>
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(image._id)}
                  >
                    <FaTrash style={{ color: "white" }} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Paginate pages={data.pages} page={data.page} isAdmin={true} />
      </>
      )}
    </>
  );
};

export default ImagesListScreen;

