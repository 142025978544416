import React from "react";
import { Form } from "react-bootstrap";

const MartyrsForm = ({
  name,
  setName,
  nameError,
  photo,
  setPhoto,
  postFileHandler,
  description,
  setDescription,
  descriptionError,
  placeOfBirth,
  setPlaceOfBirth,
  dateOfBirth,
  setDateOfBirth,
  placeOfDeath,
  setPlaceOfDeath,
  dateOfDeath,
  setDateOfDeath,
  uploadedBy,
}) => {
  return (
    <div>
      {/* ... Your Martyrs form structure here ... */}

      <Form.Group controlId="name" className="my-2">
        <Form.Label>Martyrs Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          isInvalid={!!nameError}
        />
        {nameError && (
          <Form.Control.Feedback type="invalid">
            {nameError}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="image" className="my-2">
        <Form.Label>Image</Form.Label>
        <Form.Control
          type="text"
          placeholder="enter image url"
          value={photo}
          required
          onChange={(e) => setPhoto(e.target.value)}
        />
        <Form.Control
          type="file"
          label="Choose File"
          onChange={postFileHandler}
        />
      </Form.Group>

      <Form.Group controlId="description" className="my-2">
        <Form.Label>Enter Short History</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter Short History"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          isInvalid={!!descriptionError}
        />
        {descriptionError && (
          <Form.Control.Feedback type="invalid">
            {descriptionError}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="placeOfBirth" className="my-2">
        <Form.Label>Place of Birth</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter place of birth"
          value={placeOfBirth}
          onChange={(e) => setPlaceOfBirth(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="dateOfBirth" className="my-2">
        <Form.Label>Date of Birth</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter date of birth"
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="placeOfDeath" className="my-2">
        <Form.Label>Place of Death</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter place of death"
          value={placeOfDeath}
          onChange={(e) => setPlaceOfDeath(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="dateOfDeath" className="my-2">
        <Form.Label>Date Of Death</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter date of death"
          value={dateOfDeath}
          onChange={(e) => setDateOfDeath(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="uploadedBy" className="my-2">
        <Form.Label>Uploaded By</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your name"
          value={uploadedBy}
          readOnly // This makes the input field read-only

        />
      </Form.Group>
    </div>
  );
};

export default MartyrsForm;
