import { Container } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Articles from "./components/Articles";

import Services from "./components/Services";
import Title from "./components/Title";

const App = () => {
  const location = useLocation();

  return (
    <>
      <Header />
      <main className="main-content py-3">
        <Container fluid>
    <Title text="Tigray Gallery" />
       
          <Outlet />
          {/* Only render Articles component if on the home path */}

          {location.pathname === "/" && <Articles />}
          {location.pathname === "/" && <Services />}
        </Container>
      </main>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default App;
