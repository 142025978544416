import React from "react";
import { Form } from "react-bootstrap";

const PlaceOfWorshipForm = ({
  name,
  setName,
  nameError,
  photo,
  setPhoto,
  postFileHandler,
  description,
  setDescription,
  descriptionError,
}) => {
  return (
    <div>
      {/* place of worship form structure here */}
      <div>
        <Form.Group controlId="name" className="my-2">
          <Form.Label>
            Place of Worship Name (Churches, Mosques ... )
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={!!nameError}
          />
          {nameError && (
            <Form.Control.Feedback type="invalid">
              {nameError}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group controlId="image" className="my-2">
          <Form.Label>Image</Form.Label>
          <Form.Control
            type="text"
            placeholder="enter image url"
            value={photo}
            required
            onChange={(e) => setPhoto(e.target.value)}
          />
          <Form.Control
            type="file"
            label="Choose File"
            onChange={postFileHandler}
          />
        </Form.Group>

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Enter Short History</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter Short History"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            isInvalid={!!descriptionError}
          />
          {descriptionError && (
            <Form.Control.Feedback type="invalid">
              {descriptionError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </div>
    </div>
  );
};

export default PlaceOfWorshipForm;
