import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header style={{ position: "sticky", top: "0", zIndex: "2" }}>
      <Navbar className="header" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand className="customFontSize">
              <img src="/uploads/logo-3.jpg" alt="logo" className="logo" />
              {/* <span> Tigray</span>Gallery */}
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto fontSize ">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer to="/diaspora">
                <Nav.Link>Diaspora</Nav.Link>
              </LinkContainer> */}

              <NavDropdown title="Resources">
                <LinkContainer to="/tdf">
                  <NavDropdown.Item>TDF</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/women">
                  <NavDropdown.Item>women</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/articles">
                  <NavDropdown.Item>Articles/News</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/services">
                  <NavDropdown.Item>Services</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              {/* <LinkContainer to="/contact">
                <Button className="header-add-hero">Add Your Hero</Button>
              </LinkContainer> */}

            
              {/* <LinkContainer to="/form/new">
                <Nav.Link>Create Image</Nav.Link>
              </LinkContainer> */}

              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <FaUser />
                    Log In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title="Admin" id="adminmenu">
                  <LinkContainer to="/admin/imageslist">
                    <NavDropdown.Item>Images</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/userlist">
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}

            <LinkContainer to="/contact">
                <Nav.Link className="custom-nav-link">Add Your Hero</Nav.Link>
              </LinkContainer>


            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
