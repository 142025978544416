const articleData = [
  {
    title:
      "Tigray, the UN, and the Substantial Failings of the International Community: Part One",
    description:
      "As the United Nations ICHREE mandate comes to a close, activists and experts seek to bring light to the reality of the Human Rights situation in the Tigray region of Ethiopia.....",
    imageUrl: "/uploads/tigray-3.png",
    link: "https://theatlasnews.co/latest/2023/10/08/tigray-the-un-and-the-substantial-failings-of-the-international-community-part-one/",
  },
  {
    title: "Rape survivors describe slavery, mutilation in Tigray",
    description:
      "Ethiopian and Eritrean troops have raped hundreds of women and girls during the Tigray war, subjecting some to sexual slavery and mutilation, Amnesty International said in a report Wednesday....",
    imageUrl: "/uploads/tigray-4.jpeg",
    link: "https://nation.africa/kenya/news/africa/rape-survivors-describe-slavery-mutilation-in-tigray-3506948",
  },
  {
    title:
      "Crimes Against Humanity in Tigray: Unveiling Atrocities and Pursuing Accountability",
    description:
      "The genocidal  war on Tigray, declared on November 4, 2020, has revealed the grim reality of the worst forms of crimes against humanity, war crimes, and human rights violations....",
    imageUrl: "/uploads/tigray-5.png",
    link: "https://omnatigray.org/crimes-against-humanity-in-tigray-unveiling-atrocities-and-pursuing-accountability/",
  },

  {
    title: "UN-mandated investigation into Ethiopia atrocities lapses",
    description:
      "GENEVA, Oct 4 (Reuters) - A United Nations-mandated inquiry into what investigators say are continuing atrocities in Ethiopia faces closure after a U.N. website on Wednesday showed that no motion has been received to renew it.",
    imageUrl: "/uploads/tigray-6.png",
    link: "https://www.reuters.com/world/africa/un-mandated-investigation-into-ethiopia-atrocities-lapses-2023-10-04/",
  },
  {
    title:
      "UN Human Rights Council Should Extend Investigation Commission on Ethiopia",
    description:
      "The ICHREE noted in its most recent report that “The conflict in Tigray has not ended, with Eritrean troops and Amhara militias engaging in ongoing violations.” ",
    imageUrl: "/uploads/tigray-7.png",
    link: "https://www.justsecurity.org/89012/un-human-rights-council-should-extend-investigation-commission-on-ethiopia/",
  },
];

export default articleData;
