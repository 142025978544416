import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice"
import { toast } from "react-toastify";

const RegisterScreen = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [register] = useRegisterMutation();

   const { userInfo } = useSelector((state) => state.auth); 

   const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [navigate, redirect, userInfo]);




  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
        toast.error("Passwords do not match");
        return;
    }else {
        try {
            const res = await register({ name, email, password }).unwrap();
            dispatch(setCredentials({...res, }));
            navigate(redirect);
            
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        }
    }

  };

  return (
    <div style={{ minHeight: 'calc(80vh - 60px)', display: 'flex', flexDirection: 'column' }}>
    


    <FormContainer>
      <h1>Create Account</h1>

      <Form onSubmit={submitHandler}>

      <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="name"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>



        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
        Please provide a valid email.
    </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant='primary' className='mt-2' >
            Create Account
        </Button>
      </Form>
<Row className='py-3'>
    <Col>
        Already Registered? <Link to={redirect ? `/login?redirect=${redirect}` : '/login' }>Login</Link>
       
    </Col>
</Row>
    </FormContainer>
    </div>
  );
};
export default RegisterScreen;
