


import React, { useState } from 'react';

import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Image from "../components/Image";
import { useGetImagesQuery } from "../slices/imagesApiSlice";

import ImageCarousel from "../components/ImageCarousel";
import Paginate from "../components/Paginate";
import SearchBox from "../components/SearchBox";
import Title from "../components/Title";
import Category from "../components/Category";

const HomeScreen = () => {

  const { pageNumber = 1, keyword = '' } = useParams(); // Default values set

  const [selectedCategory, setSelectedCategory] = useState(null);

  const { data: imagesData, isLoading, error } = useGetImagesQuery({ keyword, pageNumber, category: selectedCategory });
  const { data: topImages = [] } = useGetImagesQuery({ top: 10 });

  const handleCategorySelect = (category) => {
    if (category === 'all') {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
    }
  };
  
  return (
    <>
      <ImageCarousel images={topImages} />
      {isLoading ? (
        <h1>Loading...</h1>
      ) : error ? (
        <div>{error?.data?.message || error.message}</div> // Adjusted error handling
      ) : (
        <>
          <div className="home-screen">
            <Title text="Photo Gallery"  />
            <Category onCategorySelect={handleCategorySelect} currentCategory={selectedCategory} />
            <SearchBox />
            <Row>
              {keyword && (
                <Link to="/" className="goback-btn btn-light mb-4">
                  Go Back
                </Link>
              )}

               {imagesData && imagesData.images.length > 0 ? (
              imagesData.images.map((image) => (
                <Col key={image._id} sm={12} md={6} lg={4} xl={3}>
                  <Image image={image} />
                </Col>
              ))
            ) : (
              <div className="empty-message">
                No images available for the selected category.
              </div>
            )}
          </Row>
            <Paginate
              pages={imagesData.pages} // Adjusted to the correct variable name
              page={imagesData.page}   // Adjusted to the correct variable name
              keyword={keyword ? keyword : ""}
            />
          </div>
        </>
      )}
    </>
  );
};

export default HomeScreen;


