
export const about = [
  {
    id: 1,

    title: "vision",
    text: "Our vision is to become the world's most comprehensive digital platform dedicated to preserving and honoring the memories of veterans and documenting the truths of <strong style='color: #FFA07A;'>#TigrayGenocide</strong> and wartime events. We aspire to connect generations by fostering a deeper understanding of the sacrifices made and the stories untold, ensuring that the memories of the brave souls and the lessons of history remain alive for future generations.",
  },
  {
    id: 2,

    title: "mission",
    text: "Our mission is to meticulously archive and display photographs, videos, and testimonials from veterans, witnesses of <strong style='color: #FFA07A;'>#TigrayGenocide</strong> and wartime events. We aspire to connect generations by fostering a deeper understanding  and war crimes, creating a centralized repository of truth and remembrance. By leveraging digital technologies, we aim to educate the public, promote peace, and serve as a testament to the resilience of the human spirit in the face of adversity.",
  },
  {
    id: 3,

    title: "history",
    text: "Established in 2023, our platform was born out of an urgent need to document the war crimes and genocide committed by the Ethiopian government, Eritrean government, and Amhara militants against the people of Tigray.  Our collection serves not only as a tribute to the resilient spirit of Tigray but also as a potent educational tool to ensure these truths are never forgotten.",
  },
];
