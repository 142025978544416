
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import { useGetTopImagesQuery } from "../slices/imagesApiSlice";

function ImageCarousel() {
  const { data: images, isLoading, error } = useGetTopImagesQuery();

  return (
    <>
    <div className='carousel'> 
      {isLoading && <p>Loading...</p>}
      {error && <p>Something went wrong: {error}</p>}
      
      <Carousel pause="hover" className="bg-dark">
        {images &&
          images.map((image) => (
            <Carousel.Item key={image._id}>
               <Link to={`/images/${image._id}`}> 
                <Image
                  src={image.photo}
                  alt={image.name}
                  fluid
                  className="carousel-image"
                />
                <Carousel.Caption className="carousel-caption">
                  <h2>
                    {image.name} 
                  </h2>
                </Carousel.Caption>
              </Link>
            </Carousel.Item>
          ))}
      </Carousel>
      </div>
    </>
  );
}

export default ImageCarousel;

