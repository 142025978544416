import React from 'react'
import '../assets/styles/articles.css'
import articleData from '../utils/articleData'

const Articles = () => {
    return (
        <>
           <h2>News / Articles</h2>
           <div className='underline'>
            </div> 
        <div className='card-container'>
            {articleData.map((article, index) => (
                <div key={index} className="card">
                    <a href={article.link} target="_blank" rel="noopener noreferrer" className="card-link">
                        <img src={article.imageUrl} alt="News" className="card-image" />
                        <h2 className="card-title">{article.title}</h2>
                        <p className="card-text">{article.description}</p>
                        <button className="read-more-btn">Read More...</button>
                    </a>
                </div>
            ))}
        </div>
        </>
    )
}

export default Articles;
