
import React from 'react';
import { Link } from 'react-router-dom';

const Image = ({ image}) => {
  return (
    <div className="image-card">
 
      <Link to={`/images/${image._id}`}>
        <figure >
          <img src={image.photo} alt={image.name} className="custom-image" />
        </figure>
          <figcaption className='caption-content'>{image.name}</figcaption>
      </Link>
    </div>
  );
};

export default Image;
