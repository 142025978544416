import React from 'react'

const Title = ({text}) => {
  return (
    <div className='title'>
        <h1 >{text || 'Provide Title'}
        <div className="underline"></div>
        </h1>
    </div>
  )
}

export default Title
