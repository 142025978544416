import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Row, Col, ListGroup, Form, Button } from "react-bootstrap";

import { Link } from "react-router-dom";
import {
  useGetImageDetailsQuery,
  useCreateReviewMutation,
} from "../slices/imagesApiSlice";

import "../assets/styles/imageScreen.css";

const ImageScreen = () => {
  const { id: imageId } = useParams();
  const [comment, setComment] = useState("");

  const {
    data: image = {},
    isLoading,
    refetch,
    error,
  } = useGetImageDetailsQuery(imageId);

  const [createReview, { isLoading: loadingImageReview }] =
    useCreateReviewMutation();
  const { userInfo } = useSelector((state) => state.auth);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await createReview({ imageId, comment }).unwrap();
      refetch();
      toast.success("Comment added successfully!");
      setComment("");
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Link className="btn btn-dark mx-4" to="/">
        Go Back
      </Link>

      <div
        style={{
          minHeight: "calc(100vh - 60px)",
          padding: "20px",
        }}
      >
        {isLoading ? (
          <h1>Loading...</h1>
        ) : error ? (
          <div>{error?.data?.message || error.error}</div>
        ) : (
          <div className="wrapper">
            <img src={image.photo} alt={image.name} />

            <div className="details">
              <h1>Name: {image.name}</h1>

              {![
                "institutions",
                "place of worship",
                "genocide victims",
              ].includes(image.category) && (
                <>
                  <h3>
                    <span style={{ color: "black", fontSize: "2rem" }}>
                      Place Of Birth:{" "}
                    </span>
                    {image.placeOfBirth}
                  </h3>
                  <h3>
                    <span style={{ color: "black", fontSize: "2rem" }}>
                      Date of Birth:{" "}
                    </span>
                    {image.dateOfBirth}
                  </h3>
                  <h3>
                    <span style={{ color: "black", fontSize: "2rem" }}>
                      Place of Death:{" "}
                    </span>
                    {image.placeOfDeath}
                  </h3>
                  <h3>
                    <span style={{ color: "black", fontSize: "2rem" }}>
                      Date of Death:
                    </span>{" "}
                    {image.dateOfDeath}
                  </h3>
                </>
              )}
            </div>

            <div className="text-container">
              <h2>Short History</h2>
              <p>{image.description}</p>
            </div>
            <h3>Uploaded By: {image.uploadedBy}</h3>

            <div className="review-container">
              <hr />
              <Row className="review">
                <Col>
                  {image.reviews.length === 0 && <h3>No Comment</h3>}
                  <ListGroup variant="flush">
                    {image.reviews?.map((review) => (
                      <ListGroup.Item key={review._id}>
                        <strong>{review.name}</strong>
                        <p>{review.createdAt.substring(0, 10)}</p>
                        <p>{review.comment}</p>
                      </ListGroup.Item>
                    ))}
                    <ListGroup.Item>
                      <h5>
                        If you notice any inaccuracies, kindly leave a comment,
                        and we will promptly address them. Your feedback is
                        highly appreciated.
                      </h5>

                      {userInfo ? (
                        <Form onSubmit={submitHandler}>
                          <Form.Group className="my-2" controlId="comment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows="3"
                              required
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Button
                            disabled={loadingImageReview}
                            type="submit"
                            variant="primary"
                          >
                            Submit
                          </Button>
                        </Form>
                      ) : (
                        <h2>
                          Please <Link to="/login">sign in</Link> to leave a
                          comment
                        </h2>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageScreen;
