import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import App from "./App";
import ReactGA from "react-ga";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import HomeScreen from "./screens/HomeScreen";
import ImageScreen from "./screens/ImageScreen";
import About from "./components/About";
import Articles from "./components/Articles";
// import Diaspora from "./components/Diaspora";
import Women from "./components/Women";
import Tdf from "./components/Tdf";
import Contact from "./components/Contact";

import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ImagesListScreen from "./screens/admin/ImagesListScreen";
import ImageEditScreen from "./screens/admin/ImageEditScreen";
import UserListScreen from "./screens/admin/UserListScreen";
import UserEditScreen from "./screens/admin/UserEditScreen";
import Services from "./components/Services";
// import CreateImageScreen from "./screens/CreateImageScreen";
// import FormScreen from "./screens/FormScreen";

const TRACKING_ID = "G-BXECNQ289F"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route path="/search/:keyword" element={<HomeScreen />} />
      <Route path="/page/:pageNumber" element={<HomeScreen />} />
      <Route
        path="/search/:keyword/page/:pageNumber"
        element={<HomeScreen />}
      />
      <Route path="/about" element={<About />} />
      <Route path="/women" element={<Women />} />
      <Route path="/tdf" element={<Tdf />} />
      <Route path="/contact" element={<Contact />} />
      {/* <Route path="/diaspora" element={<Diaspora />} /> */}
      <Route path="/articles" element={<Articles />} />
      <Route path="/services" element={<Services />} />

      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/images/:id" element={<ImageScreen />} />
      {/* <Route path="/createimage" element={<CreateImageScreen />} /> */}
      {/* <Route path="/form/:id" element={<FormScreen />} /> */}

      {/* private routes */}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/users/imageslist" element={<ImagesListScreen />} />
        <Route path="/user/image/:id/edit" element={<ImageEditScreen />} />
      </Route>

      {/* admin routes */}
      <Route path="/" element={<AdminRoute />}>
        <Route path="/admin/imageslist" element={<ImagesListScreen />} />
        <Route
          path="/admin/imageslist/:pageNumber"
          element={<ImagesListScreen />}
        />
        <Route path="/admin/image/:id/edit" element={<ImageEditScreen />} />
        <Route path="/admin/userlist" element={<UserListScreen />} />
        <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
